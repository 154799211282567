import DataTable from "components/common/data-table/data-table";
import CategoryForm from "./category-form";
import { Category_COLUMNS } from "./category-column";
import { CategorySchema } from "./category-schema";
import { ASSETS_API, ASSETS_API_PREFIX } from "config-global";

interface CategoryProps {}

const Category: React.FC<CategoryProps> = () => {
  return (
    <div>
      <DataTable
        tableName="Category"
        parentTableName="Management"
        deleteTitle="Category"
        mainCol="title"
        getAllLink="/api/admin/challenge-categories/" //TODO add "api" to base rout
        getItemLink="/api/admin/challenge-categories" //TODO add "api" to base rout
        hasEdit={true}
        hasCreate={true}
        hasDelete={true}
        updateItemLink="/api/admin/challenge-categories" //TODO add "api" to base rout
        createItemLink="/api/admin/challenge-categories" //TODO add "api" to base rout
        deleteRowLink="/api/admin/challenge-categories" //TODO add "api" to base rout
        tableCols={Category_COLUMNS}
        FormContent={CategoryForm}
        formSchema={CategorySchema}
        formDataMapper={(data: any) => {
          return {
            ...data,
            title: data?.translations?.find(
              (translation: any) => translation.locale === "en"
            )?.title,
            titleAr: data?.translations?.find(
              (translation: any) => translation.locale === "ar"
            )?.title,
            coverImage: ASSETS_API + ASSETS_API_PREFIX + data?.coverImageUrl,
          };
        }}
        formSubmitMapper={(data: any) => {
          const formData = new FormData();
          formData.append("translations[0][locale]", "en");
          formData.append("translations[0][title]", data.title);
          formData.append("translations[1][locale]", "ar");
          formData.append("translations[1][title]", data.titleAr);
          formData.append("numberChallengeWinBadge", "1");
          formData.append("backgroundColor", data?.backgroundColor);
          formData.append("published", data?.published);
          if (data?.coverImage) {
            formData.append("coverImage", data.coverImage);
          }
          return formData;
          // return {
          //   // translations: [
          //   //   {
          //   //     locale: "en",
          //   //     title: data?.title,
          //   //   },
          //   //   {
          //   //     locale: "ar",
          //   //     title: data?.titleAr,
          //   //   },
          //   // ],
          //   // coverImage: data?.coverImage,
          //   // numberChallengeWinBadge: 1,
          //   // backgroundColor: data?.backgroundColor,
          //   // published: data?.published,
          // };
        }}
      />
    </div>
  );
};

export default Category;
