// Header.tsx
import React from "react";
import { alpha, styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Icon from "../../components/common/icon/icon";

import ukIMG from "../../assets/images/uk-flag.png";
import notificationIMG from "../../assets/icons/notification.svg";
import avatarIMG from "../../assets/images/avatar.svg";

import { useAuth } from "../../hooks/useAuth";
import { setAuthenticatedStatus } from "../../interceptors/auth";
import CustomPopover, { usePopover } from "components/minimal/custom-popover";
import { Avatar, Box, Divider, MenuItem, Stack } from "@mui/material";
import { varHover } from "components/minimal/animate";
import { m } from "framer-motion";
import { getStorage } from "hooks/use-local-storage";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps & { open?: boolean }>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  background: "linear-gradient(to right, #f87171, #fb923c)",
}));

interface HeaderProps {
  handleDrawerOpen: () => void;
  open: boolean;
}

const Header: React.FC<HeaderProps> = ({ handleDrawerOpen, open }) => {
  const { setAuthenticated } = useAuth();
  const popover = usePopover();
  const user = JSON.parse(getStorage("User"));

  const handleLogout = () => {
    setAuthenticatedStatus(false);
    setAuthenticated(false);
  };

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <div className="flex items-center flex-row justify-between w-full">
          <div className="flex flex-row items-center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {/* Mini variant drawer */}
              Dashboard
            </Typography>
          </div>

          <div className="flex items-center gap-2">
            {/* Language */}
            <img
              src={ukIMG}
              alt=""
              className="rounded-md h-6 w-8 mx-0.5 cursor-pointer"
            />

            {/* Notifications */}
            <div
              className="rounded-full overflow-hidden h-10 w-10 border-2 border-solid border-gray-100 bg-gray-100 
            flex items-center justify-center cursor-pointer"
            >
              <Icon src={notificationIMG} />
            </div>

            <IconButton
              component={m.button}
              whileTap="tap"
              whileHover="hover"
              variants={varHover(1.05)}
              onClick={popover.onOpen}
              sx={{
                width: 40,
                height: 40,
                background: (theme) => alpha(theme.palette.grey[500], 0.08),
                ...(popover.open && {
                  background: (theme) =>
                    `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                }),
              }}
            >
              <Avatar
                src={user?.avatar}
                alt={user?.username}
                sx={{
                  width: 42,
                  height: 42,
                  border: (theme) =>
                    `solid 2px ${theme.palette.background.default}`,
                }}
              />
            </IconButton>

            <CustomPopover
              open={popover.open}
              onClose={popover.onClose}
              sx={{ width: 200, p: 0 }}
            >
              <Box sx={{ p: 2, pb: 1.5 }}>
                <Typography variant="subtitle2" noWrap>
                  {user?.username}
                </Typography>

                {/* <Typography //TODO Delete this 
                  variant="body2"
                  sx={{ color: "text.secondary" }}
                  noWrap
                >
                  {user?.email}
                </Typography> */}
              </Box>

              <Divider sx={{ borderStyle: "dashed" }} />
              <MenuItem
                onClick={handleLogout}
                sx={{ m: 1, fontWeight: "fontWeightBold", color: "error.main" }}
              >
                Logout
              </MenuItem>
            </CustomPopover>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
