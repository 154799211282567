import { Stack, Typography } from "@mui/material";
// ----------------------------------------------------------------------

type Props = {
  label: string;
  value: any;
};

export default function RHFTextValue({ value, label }: Props) {
  return (
    <Stack spacing={1} direction={{ md: "row", xs: "column" }}>
      <Typography fontWeight="bold">{label}: </Typography>
      <Typography>{value}</Typography>
    </Stack>
  );
}
