// Navigation.tsx
import React from "react";
import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NavigationListItem from "./navigation-list-item";
import {
  DashboardIcon,
  StoryReportIcon,
  ChallengeIcon,
  UserIcon,
  CategoryIcon,
  ActivityIcon,
  BadgeIcon,
  AwardIcon,
  StepIcon,
  SettingsIcon,
} from "./navigation-list-item-icon";
import { useNavigate } from "react-router-dom";

import noodyIMG from "../../../assets/images/noody.svg";
import { routes } from "../../../routes/routes";
import { getStorage, setStorage } from "../../../hooks/use-local-storage";
import ChatIcon from "./navigation-list-item-icon/chat-icon";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface NavigationProps {
  open: boolean;
  handleDrawerClose: () => void;
}

const Navigation: React.FC<NavigationProps> = ({ open, handleDrawerClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const activeItem = getStorage("activeItem");

  const handleItemClick = (route: string) => {
    setStorage("activeItem", route); // Save current active view to localStorage
    navigate(route); // Navigate to the specified route
  };

  const renderNavigationListItem = (item: any) => (
    <NavigationListItem
      key={item.route}
      item={item}
      open={open}
      isActive={activeItem?.startsWith(item.route) ?? false}
      onClick={() => handleItemClick(item.route)}
    />
  );

  return (
    <Drawer variant="permanent" open={open}>
      {/* DrawerHeader >> Side Navbar Header */}
      <DrawerHeader onClick={handleDrawerClose} className="cursor-pointer">
        <img src={noodyIMG} alt="" className="w-4/5" />
        <IconButton>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>

      {/* Side Navbar List Items */}
      {open ? (
        <h4 className="text-[#637381] px-4 py-2 uppercase font-bold text-sm">
          Overview
        </h4>
      ) : (
        <Divider />
      )}

      <List>
        {[
          {
            name: "Dashboard",
            route: routes.dashboard,
            icon: (
              <DashboardIcon
                fillColor={
                  activeItem?.startsWith(routes.dashboard)
                    ? "#FF922E"
                    : "#637381"
                }
              />
            ),
          },
          {
            name: "Challenge Suggestions",
            route: routes.challengeSuggestions,
            icon: (
              <ChallengeIcon
                fillColor={
                  activeItem?.startsWith(routes.challengeSuggestions)
                    ? "#E7771052"
                    : "#CDD2D6"
                }
                strokeColor={
                  activeItem?.startsWith(routes.challengeSuggestions)
                    ? "#FF922E"
                    : "#637381"
                }
              />
            ),
          },
          {
            name: "Story Reports",
            route: routes.storyReports,
            icon: (
              <StoryReportIcon
                strokeColor={
                  activeItem?.startsWith(routes.storyReports)
                    ? "#FF922E"
                    : "#637381"
                }
              />
            ),
          },
          // {name: 'Chat', route: routes.chat,
          // icon: <ChatIcon
          // fillColor={activeItem?.startsWith(routes.chat) ? '#FF922E' : '#637381'} />}
        ].map((item, index) => renderNavigationListItem(item))}
      </List>

      {open ? (
        <h4 className="text-[#637381] px-4 py-2 uppercase font-bold text-sm">
          Users
        </h4>
      ) : (
        <Divider />
      )}

      <List>
        {[
          {
            name: "Children",
            route: routes.childrens,
            icon: (
              <UserIcon
                fillColor={
                  activeItem?.startsWith(routes.childrens)
                    ? "#FF922E"
                    : "#637381"
                }
              />
            ),
          },
          {
            name: "Parents",
            route: routes.parents,
            icon: (
              <UserIcon
                fillColor={
                  activeItem?.startsWith(routes.parents) ? "#FF922E" : "#637381"
                }
              />
            ),
          },
          {
            name: "Admins",
            route: routes.admins,
            icon: (
              <UserIcon
                fillColor={
                  activeItem?.startsWith(routes.admins) ? "#FF922E" : "#637381"
                }
              />
            ),
          },
          {
            name: "User Reports",
            route: routes.userReports,
            icon: (
              <StoryReportIcon
                // fillColor={activeItem?.startsWith(routes.userReports) ? '#FF922E' : '#637381'}
                strokeColor={
                  activeItem?.startsWith(routes.userReports)
                    ? "#FF922E"
                    : "#637381"
                }
              />
            ),
          },
        ].map((item, index) => renderNavigationListItem(item))}
      </List>

      {/* <Divider /> */}
      {open ? (
        <h4 className="text-[#637381] px-4 py-2 uppercase font-bold text-sm">
          Management
        </h4>
      ) : (
        <Divider />
      )}

      <List>
        {[
          {
            name: "Category",
            route: routes.categories,
            icon: (
              <CategoryIcon
                fillColor={
                  activeItem?.startsWith(routes.categories)
                    ? "#FF922E"
                    : "#637381"
                }
              />
            ),
          },
          {
            name: "Challenges",
            route: routes.challenges,
            icon: (
              <ChallengeIcon
                fillColor={
                  activeItem?.startsWith(routes.challenges)
                    ? "#E7771052"
                    : "#CDD2D6"
                }
                strokeColor={
                  activeItem?.startsWith(routes.challenges)
                    ? "#FF922E"
                    : "#637381"
                }
              />
            ),
          },
          {
            name: "Challenge Reports",
            route: routes.challengeUserReports,
            icon: (
              <StoryReportIcon
                // fillColor={activeItem?.startsWith(routes.challengeUserReports) ? '#FF922E' : '#637381'}
                strokeColor={
                  activeItem?.startsWith(routes.challengeUserReports)
                    ? "#FF922E"
                    : "#637381"
                }
              />
            ),
          },
          {
            name: "Badges",
            route: routes.badges,
            icon: (
              <BadgeIcon
                fillColor={
                  activeItem?.startsWith(routes.badges) ? "#FF922E" : "#637381"
                }
              />
            ),
          },
          {
            name: "Awards",
            route: routes.awards,
            icon: (
              <AwardIcon
                fillColor={
                  activeItem?.startsWith(routes.awards) ? "#FF922E" : "#98A1AC"
                }
                strokeColor={
                  activeItem?.startsWith(routes.awards) ? "#FE7E6C" : "#637381"
                }
              />
            ),
          }, // '#63738140' color with opacity 40%
          {
            name: "Steps",
            route: routes.steps,
            icon: (
              <StepIcon
                fillColor={
                  activeItem?.startsWith(routes.steps) ? "#FF922E" : "#98A1AC"
                }
                strokeColor={
                  activeItem?.startsWith(routes.steps) ? "#FE7E6C" : "#637381"
                }
              />
            ),
          },
          // {
          //   name: "Settings",
          //   route: routes.settings,
          //   icon: (
          //     <SettingsIcon
          //       fillColor={
          //         activeItem?.startsWith(routes.settings)
          //           ? "#FF922E"
          //           : "#637381"
          //       }
          //     />
          //   ),
          // },

          {
            name: "Activity",
            route: routes.activities,
            icon: (
              <ActivityIcon
                fillColor={
                  activeItem?.startsWith(routes.activities)
                    ? "#E7771052"
                    : "#555555"
                }
                strokeColor={
                  activeItem?.startsWith(routes.activities)
                    ? "#FF922E"
                    : "#637381"
                }
              />
            ),
          },
        ].map((item, index) => renderNavigationListItem(item))}
      </List>
    </Drawer>
  );
};

export default Navigation;
