import { Controller, useFormContext } from "react-hook-form";
import { TextFieldProps } from "@mui/material/TextField";
import { MuiColorInput } from "mui-color-input";
import { useState } from "react";

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  label?: string;
  isRequired?: boolean;
  minDate?: Date;
  maxDate?: Date;
  dateAndTime?: boolean;
};

export default function RHFColorPicker({
  name,
  label,
  isRequired = true,
  helperText,
  type,
  minDate,
  maxDate,
  dateAndTime,
  ...other
}: Props) {
  const { control, setValue } = useFormContext();
  const [color, setColor] = useState<string>();

  const styles = {
    "& .MuiOutlinedInput-root": {
      borderRadius: "9999px",
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "#f87171",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#f87171",
      },
    },
    "& label.Mui-focused": {
      color: "#f87171",
    },
    width: "100%",
  };

  const handleChangeColor = (color: string) => {
    setColor(color); // set the color in color picker text field
    setValue(name, color, { shouldValidate: true }); // set the color value in object key
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MuiColorInput
          {...field}
          label={label}
          name={name}
          value={color ?? field.value}
          onChange={handleChangeColor}
          isAlphaHidden={true}
          format="hex"
          size="small"
          error={!!error}
          helperText={error?.message}
          sx={styles}
        />
      )}
    />
  );
}
