// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: "/auth",
  DASHBOARD: "/dashboard",
  OVERVIEW: "/overview",
  USER: "/user",
  MANAGEMENT: "/management",
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    standard: {
      login: `${ROOTS.AUTH}/login`,
    },
  },
  // -------------------------------------------- overview routes --------------------------------------------
  overview: {
    dashboard: {
      root: `${ROOTS.OVERVIEW}/dashboard`,
      new: `${ROOTS.OVERVIEW}/dashboard/new`,
      list: `${ROOTS.OVERVIEW}/dashboard/list`,
      cards: `${ROOTS.OVERVIEW}/dashboard/cards`,
      profile: `${ROOTS.OVERVIEW}/dashboard/profile`,
      account: `${ROOTS.OVERVIEW}/dashboard/account`,
      edit: (id: string) => `${ROOTS.OVERVIEW}/dashboard/${id}/edit`,
      create: `${ROOTS.OVERVIEW}/dashboard/create`,
    },
    challengeSuggestions: {
      root: `${ROOTS.OVERVIEW}/challenge-suggestions`, //TODO reome add rout ....
      new: `${ROOTS.OVERVIEW}/challenge-suggestions/new`,
      list: `${ROOTS.OVERVIEW}/challenge-suggestions/list`,
      cards: `${ROOTS.OVERVIEW}/challenge-suggestions/cards`,
      edit: (id: string) =>
        `${ROOTS.OVERVIEW}/challenge-suggestions/${id}/edit`,
      create: `${ROOTS.OVERVIEW}/challenge-suggestions/create`,
    },
    storyReports: {
      root: `${ROOTS.OVERVIEW}/story-reports`,
    },
    chat: {
      root: `${ROOTS.OVERVIEW}/chat`,
    },
  },
  // -------------------------------------------- user routes --------------------------------------------
  user: {
    children: {
      root: `${ROOTS.USER}/childrens`,
    },
    parent: {
      root: `${ROOTS.USER}/parents`,
    },
    admin: {
      root: `${ROOTS.USER}/admins`,
    },
    userReports: {
      root: `${ROOTS.USER}/user-reports`,
    },
  },
  // -------------------------------------------- management routes --------------------------------------------
  management: {
    category: {
      //TODO reome add rout ....
      root: `${ROOTS.MANAGEMENT}/categories`,
      new: `${ROOTS.MANAGEMENT}/categories/new`,
      list: `${ROOTS.MANAGEMENT}/categories/list`,
      cards: `${ROOTS.MANAGEMENT}/categories/cards`,
      edit: (id: string) => `${ROOTS.MANAGEMENT}/categories/${id}/edit`,
      create: `${ROOTS.MANAGEMENT}/categories/create`,
    },
    challenge: {
      root: `${ROOTS.MANAGEMENT}/challenges`,
      new: `${ROOTS.MANAGEMENT}/challenges/new`,
      list: `${ROOTS.MANAGEMENT}/challenges/list`,
      cards: `${ROOTS.MANAGEMENT}/challenges/cards`,
      edit: (id: string) => `${ROOTS.MANAGEMENT}/challenges/${id}/edit`,
      create: `${ROOTS.MANAGEMENT}/challenges/create`,
    },
    challengeUserReports: {
      root: `${ROOTS.MANAGEMENT}/challenge-user-reports`,
    },
    activity: {
      root: `${ROOTS.MANAGEMENT}/activities`,
      new: `${ROOTS.MANAGEMENT}/activities/new`,
      list: `${ROOTS.MANAGEMENT}/activities/list`,
      cards: `${ROOTS.MANAGEMENT}/activities/cards`,
      edit: (id: string) => `${ROOTS.MANAGEMENT}/activities/${id}/edit`,
      create: `${ROOTS.MANAGEMENT}/activities/create`,
    },
    badge: {
      root: `${ROOTS.MANAGEMENT}/badges`,
      new: `${ROOTS.MANAGEMENT}/badges/new`,
      list: `${ROOTS.MANAGEMENT}/badges/list`,
      cards: `${ROOTS.MANAGEMENT}/badges/cards`,
      edit: (id: string) => `${ROOTS.MANAGEMENT}/badges/${id}/edit`,
      create: `${ROOTS.MANAGEMENT}/badges/create`,
    },
    award: {
      root: `${ROOTS.MANAGEMENT}/awards`,
      new: `${ROOTS.MANAGEMENT}/awards/new`,
      list: `${ROOTS.MANAGEMENT}/awards/list`,
      cards: `${ROOTS.MANAGEMENT}/awards/cards`,
      edit: (id: string) => `${ROOTS.MANAGEMENT}/awards/${id}/edit`,
      create: `${ROOTS.MANAGEMENT}/awards/create`,
    },
    step: {
      root: `${ROOTS.MANAGEMENT}/steps`,
      new: `${ROOTS.MANAGEMENT}/steps/new`,
      list: `${ROOTS.MANAGEMENT}/steps/list`,
      cards: `${ROOTS.MANAGEMENT}/steps/cards`,
      edit: (id: string) => `${ROOTS.MANAGEMENT}/steps/${id}/edit`,
      create: `${ROOTS.MANAGEMENT}/steps/create`,
    },
  },
};
