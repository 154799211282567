import DataTable from "../../../components/common/data-table/data-table";
import { USER_REPORT_COLUMNS } from "views/user/user-report/user-report-column";
import UserReportForm from "./user-report-form";

interface UserReportProps {}

const UserReport: React.FC<UserReportProps> = () => {
  return (
    <div>
      <DataTable
        tableName="User Reports"
        parentTableName="Users"
        deleteTitle="User Report"
        mainCol=""
        getAllLink="/api/admin/users/report" //TODO add "api" to base rout
        getItemLink="/api/admin/users/report" //TODO add "api" to base rout
        hasEdit={true}
        hasDelete={false}
        updateItemLink="/api/admin/users/report" //TODO add "api" to base rout
        deleteRowLink="/api/admin/users/report" //TODO add "api" to base rout
        tableCols={USER_REPORT_COLUMNS}
        FormContent={UserReportForm}
        formDataMapper={(data: any) => {
          return {
            ...data,
            status: data?.status?.value,
          };
        }}
        formSubmitMapper={(data: any) => {
          return {
            status: data?.status,
          };
        }}
      />
    </div>
  );
};

export default UserReport;
