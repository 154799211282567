import RHFRadioGroup from "components/minimal/hook-form/rhf-radio-group";
import { IFormProps } from "types/form-props";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { fDate } from "utils/format-time";
import RHFTextValue from "components/minimal/hook-form/rhf-text-value";
import { RADIO_Status_OPTIONS } from "constant/radio-status-option";
import PreviewMedia from "components/common/preview-media/preview-media";
import { useState } from "react";

interface ChallengeSuggestionFormProps extends IFormProps {}

const ChallengeSuggestionForm: React.FC<ChallengeSuggestionFormProps> = ({
  currentItem,
  formOperation,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Challenge Suggestion Information" />
          <Divider />
          <CardContent>
            <RHFTextValue label="Name" value={currentItem?.title} />
            <RHFTextValue
              label="Category"
              value={currentItem?.challengeCategory?.title}
            />
            <RHFTextValue
              label="User Name"
              value={currentItem?.user?.fullName}
            />
            <RHFTextValue label="Media Type" value={currentItem?.type?.label} />
            <RHFTextValue
              label="Created Date"
              value={fDate(currentItem?.createdAt)}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <RHFTextValue
          label="Media Link"
          value={
            <Typography
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              fontWeight={500}
              onClick={toggleForm}
            >
              Preview
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <RHFRadioGroup
          label="Status"
          name="status"
          options={RADIO_Status_OPTIONS()}
          sx={{ display: "flex", flexDirection: "row" }}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <RHFSwitch name="published" label="Published" />
      </Grid> */}
      <PreviewMedia
        urlMedia={currentItem?.mediaUrl}
        mediaType={currentItem?.type?.label}
        isOpen={isOpen}
        toggleForm={toggleForm}
      />
    </>
  );
};

export default ChallengeSuggestionForm;
