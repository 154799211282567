import * as Yup from "yup";

export const CategorySchema = Yup.object().shape({
  title: Yup.string()
    .required("Title (En) is required")
    .matches(
      /^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\s]+$/,
      "Title (En) must contain only English characters"
    ),
  titleAr: Yup.string()
    .required("Title (Ar) is required")
    .matches(
      /^[\u0600-\u06FF\s0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/,
      "Title (Ar) must contain only Arabic characters"
    ),
  // coverImage: coverImageValidation,
  backgroundColor: Yup.string().required("Background Color is required"),
  published: Yup.boolean(),
});
