import DataTable from "../../../components/common/data-table/data-table";
import { Children_COLUMNS } from "./children-column";
import ChildrenForm from "./children-form";
import { ChildrenSchema } from "./children-schema";

interface ChildrenProps {}

const Children: React.FC<ChildrenProps> = () => {
  return (
    <div>
      <DataTable
        tableName="Children"
        parentTableName="Users"
        deleteTitle="Children"
        mainCol=""
        getAllLink="/api/admin/users/children" //TODO add "api" to base rout
        getItemLink="/api/admin/users/children" //TODO add "api" to base rout
        hasDelete={false}
        updateItemLink="/api/admin/users/children" //TODO add "api" to base rout
        deleteRowLink="/api/admin/users/children" //TODO add "api" to base rout
        tableCols={Children_COLUMNS}
        FormContent={ChildrenForm}
        formSchema={ChildrenSchema}
        formSubmitMapper={(data: any) => {
          return {
            userName: data?.userName,
            firstName: data?.firstName,
            lastName: data?.lastName,
            blocked: data?.blocked,
          };
        }}
      />
    </div>
  );
};

export default Children;
