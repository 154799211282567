import { Grid, IconButton, InputAdornment } from "@mui/material";
import { RHFRoundedTextField, RHFSwitch } from "components/minimal/hook-form";
import Iconify from "components/minimal/iconify";
import { useBoolean } from "hooks/use-boolean";
import { IFormProps } from "types/form-props";

interface ChildrenFormProps extends IFormProps {}

const ChildrenForm: React.FC<ChildrenFormProps> = ({ formOperation }) => {
  const password = useBoolean();

  return (
    <>
      <Grid item xs={6}>
        <RHFRoundedTextField name="firstName" label="First Name" />
      </Grid>
      <Grid item xs={6}>
        <RHFRoundedTextField name="lastName" label="Last Name" />
      </Grid>
      <Grid item xs={6}>
        <RHFRoundedTextField
          name="username"
          label="User Name"
          autoComplete="new-password"
          disabled={formOperation === "Update"}
        />
      </Grid>
      {formOperation === "Create" && (
        <Grid item xs={6}>
          <RHFRoundedTextField
            name="password"
            label="Password"
            type={password.value ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={password.onToggle} edge="end">
                    <Iconify
                      icon={
                        password.value
                          ? "solar:eye-broken"
                          : "solar:eye-closed-bold"
                      }
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <RHFSwitch name="blocked" label="Blocked" />
      </Grid>
    </>
  );
};

export default ChildrenForm;
