import { Card, CardContent, CardHeader, Divider, Grid } from "@mui/material";
import RHFRadioGroup from "components/minimal/hook-form/rhf-radio-group";
import RHFTextValue from "components/minimal/hook-form/rhf-text-value";
import { RADIO_Status_OPTIONS } from "constant/radio-status-option";
import { IFormProps } from "types/form-props";
import { fDate } from "utils/format-time";

interface UserReportFormProps extends IFormProps {}

const UserReportForm: React.FC<UserReportFormProps> = ({
  currentItem,
  formOperation,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Story Report Information" />
          <Divider />
          <CardContent>
            <RHFTextValue label="User" value={currentItem?.user?.fullName} />

            <RHFTextValue
              label="User Type"
              value={currentItem?.user?.userType?.label}
            />
            <RHFTextValue
              label="The reporter"
              value={currentItem?.reportUser?.fullName}
            />
            <RHFTextValue
              label="The reporter type"
              value={currentItem?.reportUser?.userType?.label}
            />
            <RHFTextValue
              label="Report Date"
              value={fDate(currentItem?.reportUser?.createdAt)}
            />
            <RHFTextValue
              label="Last Updated Story"
              value={fDate(currentItem?.createdAt)}
            />
            <RHFTextValue label="Reason" value={currentItem?.reason} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <RHFRadioGroup
          label="Status"
          name="status"
          options={RADIO_Status_OPTIONS()}
          sx={{ display: "flex", flexDirection: "row" }}
        />
      </Grid>
      {/* <Grid item xs={12}>
  {rowData &&
    (rowData?.userStory?.mediaType?.label === "Image"
      ? renderImageField(rowData?.userStory?.mediaUrl)
      : renderVideoField(rowData?.userStory?.mediaUrl))}
</Grid> */}
    </>
  );
};

export default UserReportForm;
