import { Dialog, DialogContent } from "@mui/material";
import Image from "components/minimal/image";
import { ASSETS_API, ASSETS_API_PREFIX } from "config-global";
import { FC, useState } from "react";

interface PreviewMediaProps {
  urlMedia: string;
  mediaType: string;
  isOpen: boolean;
  toggleForm: any;
}

const PreviewMedia: FC<PreviewMediaProps> = ({
  urlMedia,
  mediaType,
  isOpen,
  toggleForm,
}) => {
  const renderImageField = (imageUrl: string) => (
    <Image
      ratio="1/1"
      alt="gallery"
      src={ASSETS_API + ASSETS_API_PREFIX + imageUrl}
      sx={{
        borderRadius: 1,
        border: "1px",
      }}
      effect="opacity"
    />
  );

  const renderVideoField = (videoUrl: string) => {
    return (
      <iframe
        src={`https://iframe.mediadelivery.net/embed/160614/${videoUrl}?autoplay=false&loop=false&muted=false&preload=true&responsive=true`}
        title={`${videoUrl}`}
        loading="lazy"
        style={{
          position: "absolute",
          top: 0,
          height: "100%",
          width: "100%",
          padding: 8,
          borderRadius: 24,
        }}
        allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
        allowFullScreen
      ></iframe>
    );
  };

  return (
    <Dialog open={isOpen} onClose={toggleForm} maxWidth="sm" fullWidth>
      <DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          {urlMedia &&
            (mediaType === "Video"
              ? renderVideoField(urlMedia)
              : renderImageField(urlMedia))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewMedia;
