import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { getStorage } from "hooks/use-local-storage";

export const axiosApi: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Base URL
});

axiosApi.interceptors.request.use((config) => {
  const token = getStorage("Token");
  config.headers.Authorization = `Bearer ${token}`;
  // config.headers["x-locale"] = "en";
  return config;
});

axiosApi.interceptors.response.use(
  (response: AxiosResponse) => {
    // Handle the successful response
    return response.data.data ? response.data.data : response.data;
  },
  (error: AxiosError) => {
    // If token is expired
    if (error.response?.status == 403) {
      window.location.reload();
    }
    // Handle the error response
    return Promise.reject(error.response?.data);
  }
);
