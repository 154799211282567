import * as Yup from "yup";

// ----------------------------------------------------------------------

export const ParentSchema = Yup.object().shape({
  username: Yup.string().required("Full name is required"),
  phoneNumber: Yup.string().required("Phone number is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Email must be a valid email address"),
  // password: Yup.string()
  //   .required("Password is required")
  //   .min(6, "Password should be of minimum 6 characters length"),
  blocked: Yup.boolean(),
});
