import { ADMIN_COLUMNS } from "./admin-column";
import DataTable from "../../../components/common/data-table/data-table";
import AdminForm from "./admin-form";
import { AdminSchema } from "./admin-schema";

interface AdminProps {}

const Admin: React.FC<AdminProps> = () => {
  return (
    <div>
      <DataTable
        tableName="Admins"
        deleteTitle="Admin"
        parentTableName="Users"
        mainCol=""
        tableCols={ADMIN_COLUMNS}
        FormContent={AdminForm}
        formSchema={AdminSchema}
        getAllLink="/api/admin/users/admins" //TODO add "api" to base rout
        deleteRowLink="/api/admin/users/admins" //TODO add "api" to base rout
        getItemLink="/api/admin/users/admins" //TODO add "api" to base rout
        hasCreate
        hasDelete={false}
        createItemLink="/api/admin/users/admins" //TODO add "api" to base rout
        updateItemLink="/api/admin/users/admin" //TODO add "api" to base rout
        formSubmitMapper={(data: any) => {
          return {
            username: data?.username,
            password: data?.password,
            phoneNumber: data?.phoneNumber,
            email: data?.email,
            blocked: data?.blocked ?? false,
            role: 1,
          };
        }}
      />
    </div>
  );
};

export default Admin;
