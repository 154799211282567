export const CHALLENGE_USER_REPORT_COLUMNS = [
  { id: "challengeUserId", label: "Id", width: "10%" },
  {
    id: "challengeUser",
    label: "Challenge Username",
    width: "18%",
    isObjObj: true,
    child: "user",
    child2: "username",
  },
  {
    id: "user",
    label: "Reporting Username",
    width: "18%",
    isObj: true,
    child: "username",
  },
  { id: "createdAt", label: "Reporting Date", width: "18%", isDate: true },
  {
    id: "status",
    label: "Status",
    width: "15%",
    isObjList: true,
    list: ["Approved", "Pending", "Reject"],
    child: "label",
  },
  {
    id: "isDeleted",
    label: "IsDeleted",
    width: "7%",
    isList: true,
    list: [false, true],
    clickable: true,
  },
  { id: "reason", label: "Reason", width: "35%" },
  { id: "actions", width: "15%" },
];
