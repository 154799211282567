export const STORY_REPORT_COLUMNS = [
  { id: "storyId", label: "Story Id", width: "18%" },
  {
    id: "userStory",
    label: "Story Username",
    width: "18%",
    isObjObj: true,
    child: "user",
    child2: "username",
  },
  {
    id: "user",
    label: "Reporting Username",
    width: "18%",
    isObj: true,
    child: "username",
  },
  {
    id: "userType",
    label: "User Type",
    width: "12%",
    isObjList: true,
    list: ["Child", "Parent", "Admin"],
    child: "label",
  },
  {
    id: "status",
    label: "Status",
    width: "12%",
    isObjList: true,
    list: ["Approved", "Pending", "Reject"],
    child: "label",
  },

  {
    id: "isDeleted",
    label: "IsDeleted",
    width: "7%",
    isList: true,
    list: [false, true],
    clickable: true,
  },
  { id: "createdAt", label: "Reporting Date", width: "22%", isDate: true },
  { id: "reason", label: "Reason", width: "35%" },
  { id: "actions", width: "22%" },
];
