import Dashboard from "../views/overview/dashboard/dashboard-list-view";
import ChallengeSuggestion from "../views/overview/challenge-suggestion/challenge-suggestion-list-view";
import StoryReport from "../views/overview/story-report/story-report-list-view";
import Chat from "../views/overview/chat/chat-view";
import Admin from "../views/user/admin/admin-list-view";
import Parent from "../views/user/parent/parent-list-view";
import UserReport from "../views/user/user-report/user-report-list-view";
import Category from "../views/management/category/category-list-view";
import Challenge from "../views/management/challenge/challenge-list-view";
import CreateChallenge from "../views/management/challenge/challenge-create-view";
import EditChallenge from "../views/management/challenge/challenge-edit-view";
import ChallengeUserReport from "../views/management/challenge-user-report/challenge-user-report-list-view";
import Activity from "../views/management/activity/activity-list-view";
import Badge from "../views/management/badge/badge-list-view";
import CreateBadge from "../views/management/badge/badge-create-view";
import EditBadge from "../views/management/badge/badge-edit-view";
import Award from "../views/management/award/award-list-view";
import CreateAward from "../views/management/award/award-create-view";
import EditAward from "../views/management/award/award-edit-view";
import Step from "../views/management/step/step-list-view";
import CreateStep from "../views/management/step/step-create-view";
import EditStep from "../views/management/step/step-edit-view";
import { paths } from "./paths";
import Children from "views/user/children/children-list-view";

interface AppRoute {
  path: any;
  element: React.ReactElement;
}

export const routes = {
  // ----------------------------------------------------------------------
  //Auth Routes
  login: paths.auth.standard.login,

  // ----------------------------------------------------------------------
  // Overview Routes
  dashboard: paths.overview.dashboard.root,
  challengeSuggestions: paths.overview.challengeSuggestions.root,
  challengeSuggestionsCreate: paths.overview.challengeSuggestions.create,
  storyReports: paths.overview.storyReports.root,
  chat: paths.overview.chat.root,

  // ----------------------------------------------------------------------
  // User Routes
  childrens: paths.user.children.root,
  parents: paths.user.parent.root,
  admins: paths.user.admin.root,
  userReports: paths.user.userReports.root,

  // ----------------------------------------------------------------------
  // Management Routes
  // Category Routes
  categories: paths.management.category.root,
  categoriesCreate: paths.management.category.create,
  // 'categoriesEdit': paths.management.category.edit,
  categoriesEdit: (id: string) => paths.management.category.edit(id),
  // Challenge Routes
  challenges: paths.management.challenge.root,
  challengesCreate: paths.management.challenge.create,
  challengesEdit: (id: string) => paths.management.challenge.edit(id),
  // Challenge Reports Routes
  challengeUserReports: paths.management.challengeUserReports.root,
  // Activity Routes
  activities: paths.management.activity.root,
  // Badge Routes
  badges: paths.management.badge.root,
  badgesCreate: paths.management.badge.create,
  badgesEdit: (id: string) => paths.management.badge.edit(id),
  // Award Routes
  awards: paths.management.award.root,
  awardsCreate: paths.management.award.create,
  awardsEdit: (id: string) => paths.management.award.edit(id),
  // Step Routes
  steps: paths.management.step.root,
  stepsCreate: paths.management.step.create,
  stepsEdit: (id: string) => paths.management.step.edit(id),
  // Settings Routes
  //   settings: paths.management.settings.root,
};

export const routesComponents: AppRoute[] = [
  // Overview Routes to Components
  { path: routes.dashboard, element: <Dashboard /> },
  { path: routes.challengeSuggestions, element: <ChallengeSuggestion /> },
  { path: routes.storyReports, element: <StoryReport /> },

  // ----------------------------------------------------------------------
  // User Routes to Components
  { path: routes.chat, element: <Chat /> },
  { path: routes.childrens, element: <Children /> },
  { path: routes.parents, element: <Parent /> },
  { path: routes.admins, element: <Admin /> },
  { path: routes.userReports, element: <UserReport /> },

  // ----------------------------------------------------------------------
  // Management Routes to Components
  // Category Routes to Components
  { path: routes.categories, element: <Category /> },
  // Challenge Routes to Components
  { path: routes.challenges, element: <Challenge /> },
  { path: routes.challengesCreate, element: <CreateChallenge /> },
  { path: routes.challengesEdit(":id"), element: <EditChallenge /> },
  // Challenge User Reports Routes to Components
  { path: routes.challengeUserReports, element: <ChallengeUserReport /> },
  // Activity Routes to Components
  { path: routes.activities, element: <Activity /> },
  // Badge Routes to Components
  { path: routes.badges, element: <Badge /> },
  { path: routes.badgesCreate, element: <CreateBadge /> },
  { path: routes.badgesEdit(":id"), element: <EditBadge /> },
  // Award Routes to Components
  { path: routes.awards, element: <Award /> },
  { path: routes.awardsCreate, element: <CreateAward /> },
  { path: routes.awardsEdit(":id"), element: <EditAward /> },
  // Step Routes to Components
  { path: routes.steps, element: <Step /> },
  { path: routes.stepsCreate, element: <CreateStep /> },
  { path: routes.stepsEdit(":id"), element: <EditStep /> },
  // Settings Routes to Components
  // {path: routes.settings, element: <Settings />},
];
