import { Grid, IconButton, InputAdornment } from "@mui/material";
import {
  RHFRoundedTextField,
  RHFSwitch,
} from "../../../components/minimal/hook-form";
import { IFormProps } from "types/form-props";
import { useBoolean } from "hooks/use-boolean";
import Iconify from "components/minimal/iconify";

interface ParentFormProps extends IFormProps {}

const ParentForm: React.FC<ParentFormProps> = ({
  currentItem,
  formOperation,
}) => {
  const password = useBoolean();

  return (
    <>
      <Grid item xs={6}>
        <RHFRoundedTextField
          name="username"
          label="User Name"
          autoComplete="new-password"
          disabled={formOperation === "Update"}
        />
      </Grid>
      {formOperation === "Create" && (
        <Grid item xs={6}>
          <RHFRoundedTextField
            name="password"
            label="Password"
            type={password.value ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={password.onToggle} edge="end">
                    <Iconify
                      icon={
                        password.value
                          ? "solar:eye-broken"
                          : "solar:eye-closed-bold"
                      }
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <RHFRoundedTextField name="phoneNumber" label="Phone Number" />
      </Grid>
      <Grid item xs={6}>
        <RHFRoundedTextField
          name="email"
          label="Email Address"
          autoComplete="new-password"
        />
      </Grid>
      <Grid item xs={6}>
        <RHFSwitch name="blocked" label="Blocked" />
      </Grid>
    </>
  );
};

export default ParentForm;
