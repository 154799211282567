import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  Box,
  Card,
  Grid,
  Button,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FormProvider from "../../minimal/hook-form";
import useApiServices from "api/useApiServices";
import { IFormOperation } from "types/form-operation";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

// ----------------------------------------------------------------------

interface ParentFormProps {
  handleClose?: VoidFunction;
  currentItem?: any;
  formSchema?: any;
  FormContent?: any;
  getItemLink?: string;
  getAllLink?: string;
  createItemLink?: string;
  updateItemLink?: string;
  formSubmitMapper?: (data: any) => any;
  formDataMapper?: (data: any) => any;
  setOpenForm: any;
  formOperation: IFormOperation;
}

// ----------------------------------------------------------------------

const GeneralForm: React.FC<ParentFormProps> = ({
  currentItem: currentItemProps,
  handleClose,
  formSchema,
  FormContent,
  getItemLink,
  getAllLink,
  createItemLink,
  updateItemLink,
  formSubmitMapper,
  formDataMapper,
  setOpenForm,
  formOperation,
}) => {
  const { usePostApi, usePatchApi, useGetItemApi } = useApiServices();
  const isUpdate = !!currentItemProps;
  const [currentItem, setCurrentItem] = useState(currentItemProps);
  const [isLoading, setIsLoading] = useState(isUpdate);
  const queryClient = useQueryClient();

  const methods = useForm({
    resolver: formSchema && yupResolver(formSchema),
    defaultValues: {},
  });

  const {
    setValue,
    handleSubmit,
    reset,
    resetField,
    getValues,
    formState: { isSubmitting },
  } = methods;

  const { isLoading: loadingGet, remove } = useGetItemApi({
    url: getItemLink ?? "",
    id: String(currentItem?.id),
    enabled: isUpdate,
    onSuccess: (data: any) => {
      setIsLoading(false);
      reset(formDataMapper ? formDataMapper(data) : data);
      setCurrentItem(formDataMapper ? formDataMapper(data) : data);
    },
  });

  const { mutate: createParect } = usePostApi({
    url: createItemLink ?? "",
    onSuccess: (data: any) => {
      setOpenForm(false);
      queryClient.invalidateQueries([getAllLink]);
    },
  });

  const { mutate: updateParect } = usePatchApi({
    url: updateItemLink ?? "",
    id: String(currentItem?.id),
    onSuccess: (data: any) => {
      setOpenForm(false);
      queryClient.invalidateQueries([getAllLink]);
    },
  });

  const onSubmit = (data: any) => {
    formOperation === "Update"
      ? updateParect(formSubmitMapper ? formSubmitMapper(data) : data)
      : createParect(formSubmitMapper ? formSubmitMapper(data) : data);
  };

  // useEffect(() => {
  //   const resetObject = Object.keys(getValues()).reduce(
  //     (acc: any, key: any) => {
  //       acc[key] = ""; // Set each field to undefined
  //       return acc;
  //     },
  //     {}
  //   );

  //   if (!isUpdate) {
  //     reset(resetObject);
  //     remove();
  //   }
  // }, [open]);

  return (
    <>
      {isLoading && formOperation !== "Create" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Card sx={{ p: 3, minHeight: "26rem" }}>
            <Grid container spacing={2}>
              <FormContent
                currentItem={currentItem}
                formOperation={formOperation}
                setValue={setValue}
              />
              {/* TODO add all thing props to form and to Iform type*/}
            </Grid>
          </Card>
          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={handleClose}>
              Close
            </Button>
            {formOperation !== "View" && (
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                loading={isSubmitting}
                style={{
                  borderRadius: 20,
                  textTransform: "capitalize",
                }}
                className="bg-gradient-to-br from-red-400 to-orange-400 capitalize"
              >
                {formOperation === "Update" ? "Update" : "Create"}
              </LoadingButton>
            )}
          </DialogActions>
        </FormProvider>
      )}
    </>
  );
};

export default GeneralForm;
