// App.tsx
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import Login from "./views/auth/login-view";
import Layout from "./layout/layout";

import { routesComponents } from "./routes/routes";
import { useAuth } from "./hooks/useAuth";
import { setStorage } from "./hooks/use-local-storage";
import DeleteAcount from "./views/auth/delete-account-view";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// ----------------------------------------------------------------------

// Rendering Page Content in Layout Content Depend on Route
function ProtectedRoute({
  layout: LayoutComponent,
  component: Component,
  path,
}: {
  layout: React.ComponentType<React.ComponentProps<typeof Layout>>;
  component: React.ComponentType;
  path: string;
}) {
  const { isAuthenticated } = useAuth();
  setStorage('activeItem', path);
  return isAuthenticated ? (
    <LayoutComponent>
      <Component />
    </LayoutComponent>
  ) : (
    <Navigate to="/auth/login" />
  );
}

function App() {
  const { isAuthenticated } = useAuth();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}> 
      <Routes>
        {/* Root route ("/") */}
        <Route
          path="/"
          element={
            isAuthenticated ? <Navigate to="/overview/dashboard" /> : <Navigate to="/auth/login" />
          }
        />

      <Route
          path="/auth/delete-account"
          element={
            <DeleteAcount />
          }
        />

        {/* Non-layout routes (e.g., login) */}
        {/* Login route ("/auth/login") */}
        <Route
          path="/auth/login"
          element={
            isAuthenticated ? (
              // Redirect to dashboard if authenticated
              <Navigate to="/overview/dashboard" />
            ) : (
              // Redirect to login if not authenticated
              <Login />
            )
          }
        />

        {/* Layout routes (e.g., dashboard) */}
        {/* Dashboard route ("/overview/dashboard") */}
        {routesComponents.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute
                layout={Layout}
                component={() => element}
                path = {path}
              />
            }
          />
        ))}
      </Routes>
      </QueryClientProvider>
  );
}

export default App;
