export const Category_COLUMNS = [
  { id: "coverImageUrl", label: "Cover", width: "10%", isImage: true },
  { id: "title", label: "Title", width: "30%" },
  { id: "createdAt", label: "Created Date", width: "30%", isDate: true },
  {
    id: "published",
    label: "Published",
    width: "15%",
    isList: true,
    list: [true, false],
  },
  { id: "actions", width: "15%" },
];
