import React, {
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { yupResolver } from "@hookform/resolvers/yup";

import { useNavigate, useParams } from "react-router-dom";

import { getChallengeSchema } from "../challenge-schema";
import { MEDIA_TYPES_OPTIONS } from "../../../../constant/challenge";

import { fData } from "../../../../utils/format-number";

import {
  ASSETS_API,
  ASSETS_API_PREFIX,
  HOST_API,
} from "../../../../config-global";
import axios from "axios";
import { handleErrors } from "../../../../handlers/axiosErrorsHandler";

import { routes } from "../../../../routes/routes";

import { getStorage } from "../../../../hooks/use-local-storage";
import { useAuth } from "../../../../hooks/useAuth";

import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
  MenuItem,
  CircularProgress,
  Divider,
  IconButton,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { Controller, useForm } from "react-hook-form";
import FormProvider, {
  RHFRoundedTextField,
  RHFMultiLineTextField,
  RHFRoundedSelect,
  RHFUpload,
} from "../../../../components/minimal/hook-form";
import { useSnackbar } from "../../../../components/minimal/snackbar";

import Image from "../../../../components/minimal/image";
import Iconify from "../../../../components/minimal/iconify";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

import noDataIMG from "../../../../assets/empty/no-data.svg";

import * as tus from "tus-js-client";

// ----------------------------------------------------------------------

const styles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "9999px",
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "#f87171",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#f87171",
    },
  },
  "& label": {
    // color: 'red',
  },
  "& label.Mui-focused": {
    color: "#f87171",
  },
};

// ----------------------------------------------------------------------

interface ChallengeFormProps {
  onClose: VoidFunction;
}

// ----------------------------------------------------------------------

const ChallengeForm: React.FC<ChallengeFormProps> = ({ onClose }) => {
  const { setAuthenticated } = useAuth();

  const [loading, setLoading] = useState<boolean>(true); // State to track loading state
  const [objData, setObjData] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false); // Initialize loading state

  const token = getStorage("Token");

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams(); // Extract challengeId from the URL
  const isUpdate = id !== undefined; // Check if it's an update

  const ChallengeSchema = useMemo(
    () => getChallengeSchema(isUpdate),
    [isUpdate]
  );

  //must default values attributes is equal to categorySchema
  const defaultValues = useMemo(
    () => ({
      title: "",
      titleAr: "",
      description: "",
      descriptionAr: "",
      coverImage: null,
      startDate: null,
      endDate: null,
      challengeCategory: 0,
      type: "None",
      media: null,
      published: true,
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(ChallengeSchema),
    defaultValues,
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const [loadingCategories, setLoadingCategories] = useState(true);

  // Use useRef to store categories
  const categoriesRef = useRef<any>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        await axios
          .get(`${HOST_API}/api/admin/challenge-categories`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-locale": "en",
            },
          })
          .then((res) => {
            var response = res.data;
            // Update the current value of categoriesRef
            categoriesRef.current = response.data.items;
            setLoadingCategories(false);
          });
      } catch (error: any) {
        handleErrors(error, setAuthenticated, enqueueSnackbar);
      }
    };

    const fetchChallengeData = async () => {
      try {
        const response = await axios.get(
          `${HOST_API}/api/admin/challenges/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-locale": "en",
            },
          }
        );
        const challengeData = response?.data?.data; // Assuming the API response contains the challenge data
        setObjData(challengeData);
        const enTranslation = challengeData.translations.find(
          (translation: any) => translation.locale === "en"
        );
        // If 'en' translation exists, set titleAr to its title, otherwise set it to an empty string
        const titleEnValue = enTranslation ? enTranslation.title : "";
        setValue("title", titleEnValue);
        if (enTranslation?.description) {
          setValue("description", enTranslation.description);
        }
        // Find the translation object with locale 'ar' in the translations array
        const arTranslation = challengeData.translations.find(
          (translation: any) => translation.locale === "ar"
        );
        // If 'ar' translation exists, set titleAr to its title, otherwise set it to an empty string
        const titleArValue = arTranslation ? arTranslation.title : "";
        setValue("titleAr", titleArValue);
        if (arTranslation?.description) {
          setValue("descriptionAr", arTranslation.description);
        }

        setValue(
          "startDate",
          challengeData?.startDate ? new Date(challengeData?.startDate) : null
        );
        setValue(
          "endDate",
          challengeData?.endDate ? new Date(challengeData?.endDate) : null
        );

        if (
          !challengeData?.type?.value ||
          challengeData?.type?.value === "TEXT"
        ) {
          setValue("type", "None");
        } else {
          setValue("type", challengeData?.type?.value || "None");
        }
        setValue(
          "challengeCategory",
          challengeData?.challengeCategory?.id || 0
        );
        setValue("published", challengeData?.published);

        setLoading(false); // Set loading to false when data fetching is complete
      } catch (error) {
        handleErrors(error, setAuthenticated, enqueueSnackbar);
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    fetchCategories();

    if (isUpdate) {
      // Update State
      fetchChallengeData();
    } else {
      // Create State
      setLoading(false);
    }
  }, [
    id,
    token,
    setValue,
    setAuthenticated,
    isUpdate,
    enqueueSnackbar,
    setObjData,
  ]);

  //handle upload file
  const handleDropFile = useCallback(
    (field: any, acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue(field, newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );
  // Usage
  const handleDropCoverImage = useCallback(
    (acceptedFiles: File[]) => {
      handleDropFile("coverImage", acceptedFiles);
    },
    [handleDropFile]
  );

  const [videoSrc, setVideoSrc] = useState<string | null>(null); // Update the type of videoSrc

  const handleDropMedia = useCallback(
    (acceptedFiles: File[]) => {
      handleDropFile("media", acceptedFiles);

      // Check the type of the uploaded file
      const file = acceptedFiles[0];
      const fileType = file.type;

      // Determine the mediaType based on the file type
      let mediaType = "None"; // Default value
      if (fileType.includes("image")) {
        mediaType = "IMAGE";
      } else if (fileType.includes("video")) {
        mediaType = "VIDEO";
        // Create a URL for the selected video file
        const videoURL = URL.createObjectURL(file);
        // Set the created URL as the source of the video element
        setVideoSrc(videoURL);
      }

      // Update the value of the mediaType field
      setValue("type", mediaType);
    },
    [handleDropFile, setValue]
  );

  const handleRemoveImage = useCallback(() => {
    setObjData((prevObjData: any) => {
      return { ...prevObjData, coverImageUrl: "" };
    });
  }, [setObjData]);

  const handleRemoveMedia = useCallback(() => {
    // Reset the state related to the uploaded video file
    // Clear the video source state
    setVideoSrc(null);
    // Update the value of the mediaType field to indicate that there is no media
    setValue("type", "None");
    // Clear the uploaded video file from form data
    setValue("media", null, { shouldValidate: true });
  }, [setVideoSrc, setValue]);

  const [upload, setUpload] = useState<tus.Upload | null>(null);
  const [chunkSize, setChunkSize] = useState(Infinity);

  const onSubmit = handleSubmit(async (data) => {
    console.log(upload);
    // if mediaType is VIDEO
    try {
      setFormLoading(true); // Set loading state to true when submission starts
      const formData = new FormData();
      // Append other form data to formData
      // Check if the type is 'Video'
      if (data.type === "VIDEO" && !isUpdate) {
        // Make the API call to create a video
        await axios
          .post(
            `${HOST_API}/api/admin/create-video`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "x-locale": "en",
              },
            }
          )
          .then(async (response) => {
            // Handle success response
            const res = response.data.data;
            console.log("VideoId:", res.VideoId);
            formData.append("mediaUrl", res.VideoId);

            var file = data.media; //File

            setChunkSize(Infinity);

            var options = {
              endpoint: "https://video.bunnycdn.com/tusupload/",
              chunkSize: chunkSize, // refers to the size of individual chunks into which a file is divided before being transmitted to the server.
              retryDelays: [0, 1000, 3000, 5000, 10000, 20000, 60000, 60000],
              parallelUploads: 1,
              headers: {
                AuthorizationSignature: res.AuthorizationSignature, // SHA256 signature (library_id + api_key + expiration_time + video_id)
                AuthorizationExpire: res.AuthorizationExpire, // Expiration time as in the signature,
                VideoId: res.VideoId, // The guid of a previously created video object through the Create Video API call
                LibraryId: res.LibraryId, // BUNNY LIBRARY ID >> 160614
              },
              metadata: {
                fileName: file.name,
                filetype: file.type,
              },
              onError: (error: any) => {
                // Error handling logic
                console.error("Upload error:", error);
                enqueueSnackbar("Upload error: " + error, { variant: "error" });
                setFormLoading(false); // Set loading state to false when submission completes
              },
              onProgress: (bytesUploaded: any, bytesTotal: any) => {
                const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(
                  2
                );
                console.log("Upload progress:", percentage + "%");
                // Progress tracking logic
              },
              onSuccess: () => {
                console.log("Upload successful");
                // Success handling logic
                handleRequest(data, formData);
              },
            };

            const newUpload = new tus.Upload(file, options);
            setUpload(newUpload);
            newUpload.findPreviousUploads().then((previousUploads) => {
              // askToResumeUpload(previousUploads, newUpload);
              newUpload.start();
              // setUploadIsRunning(true);
            });
          });
      } else {
        // Handle other types of challenges
        // Make the API call to create/update the challenge
        await handleRequest(data, formData);
      }
    } catch (error) {
      // Handle errors
      // reset();
      handleErrors(error, setAuthenticated, enqueueSnackbar);
    }
  });

  const handleRequest = async function handleRequest(
    data: any,
    formData: FormData
  ) {
    try {
      // Add 10 hours to the start and end dates to fix return date -1 day because of utc time
      const startDate = new Date(data.startDate);
      startDate.setHours(startDate.getHours() + 10);
      const endDate = new Date(data.endDate);
      endDate.setHours(endDate.getHours() + 10);
      formData.append("translations[0][locale]", "en");
      formData.append("translations[0][title]", data.title);
      formData.append("translations[0][description]", data.description);
      formData.append("translations[1][locale]", "ar");
      formData.append("translations[1][title]", data.titleAr);
      formData.append("translations[1][description]", data.descriptionAr);

      if (!isUpdate || data.coverImage) {
        formData.append("coverImage", data.coverImage); // File
      }
      formData.append("startDate", startDate.toISOString());
      formData.append("endDate", endDate.toISOString());

      if (!isUpdate) {
        formData.append("challengeCategory", String(data.challengeCategory));
        formData.append("type", data.type);
        if (data.type === "IMAGE") {
          formData.append("media", data.media); //File
        }
      }

      if (isUpdate) {
        // Update operation
        await axios.patch(`${HOST_API}/api/admin/challenges/${id}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-locale": "en",
          },
        });
      } else {
        // Create operation
        await axios.post(`${HOST_API}/api/admin/challenges`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-locale": "en",
          },
        });
      }
      setFormLoading(false); // Set loading state to false when submission completes
      enqueueSnackbar(isUpdate ? "Update success!" : "Create success!");
      navigate(routes.challenges);
    } catch (error) {
      handleErrors(error, setAuthenticated, enqueueSnackbar);
      setFormLoading(false); // Set loading state to false when submission completes
    }
  };

  const renderDropDownField = (
    name: string,
    label: string,
    options: any,
    noSelectedValue: any,
    byValue: boolean,
    readOnly: boolean = false
  ) => (
    <RHFRoundedSelect
      name={name}
      size="small"
      label={label}
      id={name} // Pass the unique id to the RHFRoundedSelect component
      InputLabelProps={{ shrink: true, htmlFor: name + "-label" }} // Match the htmlFor prop
      // InputLabelProps={{ shrink: true }} // Match the htmlFor prop
      // sx={{
      // maxWidth: { md: 160 },
      // }}
      disabled={readOnly} // Add the disabled attribute based on the readOnly parameter
      sx={styles}
    >
      <MenuItem
        value={noSelectedValue}
        // onClick={() => handleClearService(index)}
        sx={{ fontStyle: "italic", color: "text.secondary" }}
      >
        None
      </MenuItem>

      <Divider sx={{ borderStyle: "dashed" }} />

      {options.map((item: any) => (
        <MenuItem
          key={item.id}
          value={item.id}
          // onClick={() => handleSelectService(index, item.value)}
        >
          {byValue ? item.value : item.title}
        </MenuItem>
      ))}
    </RHFRoundedSelect>
  );

  const renderDatePickerField = (name: any, label: string) => (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            {...field}
            label={label}
            minDate={new Date()} // for disabled dates before today in datepicker
            format="dd/MM/yyyy"
            slotProps={{
              textField: {
                fullWidth: true,
                error: !!error,
                helperText: error?.message,
                size: "small",
                // margin: "dense",
                sx: styles,
              },
            }}
            // id={name} // Add this line to set the id
          />{" "}
          //TODO use datepicker in minimal
        </LocalizationProvider>
      )}
    />
  );

  const renderUploadFileField = (
    name: any,
    allowedExt: string,
    accept: any,
    onDropCallback: (files: File[]) => void
  ) => (
    <RHFUpload
      name={name}
      maxSize={3145728}
      // maxSize={7145728}
      // maxSize={50145728}
      accept={accept}
      // onDrop={handleDropFile}
      onDrop={(files) => onDropCallback(files)}
      onDelete={() => setValue(name, null, { shouldValidate: true })}
      helperText={
        <Typography
          variant="caption"
          sx={{
            mt: 3,
            mx: "auto",
            display: "block",
            textAlign: "center",
            color: "text.disabled",
          }}
        >
          {/* Allowed *.jpeg, *.jpg, *.png, *.gif */}
          {allowedExt}
          <br /> max size of {fData(3145728)}
          {/* <br /> max size of {fData(7145728)} */}
          {/* <br /> max size of {fData(50145728)} */}
        </Typography>
      }
    />
  );

  const renderImageField = (imageUrl: string, hasIcon = true) => (
    <div style={{ position: "relative" }}>
      <Card sx={{ p: 1, mt: 0.5 }}>
        <Image
          ratio="1/1"
          alt="gallery"
          src={ASSETS_API + ASSETS_API_PREFIX + imageUrl}
          sx={{
            borderRadius: 1,
            width: "100%",
            height: "300px",
            border: "1px",
            padding: 2,
          }}
          effect="opacity"
        />
      </Card>

      {/* Remove Image Icon */}
      {hasIcon && (
        <Box sx={{ position: "absolute", top: -12, right: -12 }}>
          <IconButton
            onClick={handleRemoveImage}
            sx={{
              backgroundColor: "#FF922E",
              "&:hover": { backgroundColor: "#FF7F00" },
            }}
          >
            <Iconify icon={"solar:trash-bin-trash-bold"} color="white" />
          </IconButton>
        </Box>
      )}
    </div>
  );

  const renderEmptyImageField = (imageUrl: string, hasIcon = true) => (
    <div style={{ position: "relative" }}>
      <Card sx={{ p: 1, mt: 0.5 }}>
        <Image
          ratio="1/1"
          alt="gallery"
          src={imageUrl}
          objectFit="contain"
          sx={{
            borderRadius: 1,
            width: "100%",
            height: "250px",
            border: "1px",
            padding: 2,
          }}
          effect="opacity"
        />
        <Typography
          variant="caption"
          sx={{
            display: "block",
            fontSize: "14pt",
            fontWeight: "bold",
            textAlign: "center",
            color: "text.disabled",
          }}
        >
          No Media
        </Typography>
      </Card>

      {/* Remove Image Icon */}
      {hasIcon && (
        <Box sx={{ position: "absolute", top: -12, right: -12 }}>
          <IconButton
            onClick={handleRemoveImage}
            sx={{
              backgroundColor: "#FF922E",
              "&:hover": { backgroundColor: "#FF7F00" },
            }}
          >
            <Iconify icon={"solar:trash-bin-trash-bold"} color="white" />
          </IconButton>
        </Box>
      )}
    </div>
  );

  const renderVideoField = (videoUrl: string) => {
    return (
      <Card sx={{ mt: 0.5 }} style={{ paddingTop: "56.25%" }}>
        <iframe
          src={`https://iframe.mediadelivery.net/embed/160614/${videoUrl}?autoplay=false&loop=false&muted=false&preload=true&responsive=true`}
          title={`${videoUrl}`}
          loading="lazy"
          style={{
            position: "absolute",
            top: 0,
            height: "100%",
            width: "100%",
            padding: 8,
            borderRadius: 24,
          }}
          allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
          allowFullScreen
        ></iframe>
      </Card>
    );
  };

  const renderVideoBeforeUploadField = () => {
    return (
      videoSrc && (
        <div style={{ position: "relative" }}>
          <Card sx={{ p: 1, mt: 0.5 }}>
            <video
              controls
              width="100%"
              style={{ borderRadius: "1rem", height: "300px" }}
            >
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Card>
          <Box sx={{ position: "absolute", top: -12, right: -12 }}>
            <IconButton
              onClick={handleRemoveMedia}
              sx={{
                backgroundColor: "#FF922E",
                "&:hover": { backgroundColor: "#FF7F00" },
              }}
            >
              <Iconify icon={"solar:trash-bin-trash-bold"} color="white" />
            </IconButton>
          </Box>
        </div>
      )
    );
  };

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3, marginBottom: "1.5rem" }}>
              <Stack spacing={3}>
                {loadingCategories ? (
                  <div className="flex items-center justify-center">
                    <CircularProgress />
                  </div>
                ) : (
                  renderDropDownField(
                    "challengeCategory",
                    "Category",
                    categoriesRef.current,
                    0,
                    false,
                    isUpdate
                  )
                )}
              </Stack>

              {/* for half width of row for each field use box */}
              <Box
                sx={{ mt: 3 }}
                rowGap={3} // gap between form rows
                columnGap={2} // gap between form columns
                display="grid"
                gridTemplateColumns={{
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                }}
              >
                {renderDatePickerField("startDate", "Start Date")}
                {renderDatePickerField("endDate", "End Date")}
              </Box>
            </Card>

            <Card sx={{ p: 3 }}>
              <Box
                rowGap={3} // gap between form rows
                columnGap={2} // gap between form columns
                display="grid"
                gridTemplateColumns={{
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                }}
              >
                <RHFRoundedTextField name="title" label="Title (En)" />
                <RHFRoundedTextField name="titleAr" label="Title (Ar)" />
              </Box>

              <Stack spacing={3} sx={{ mt: 3 }}>
                <RHFMultiLineTextField
                  name="description"
                  label="Description (En)"
                  multiline
                  rows={4}
                />
                <RHFMultiLineTextField
                  name="descriptionAr"
                  label="Description (Ar)"
                  multiline
                  rows={4}
                />
              </Stack>

              {/* Upload File Rectangle with Dropzone and Svg Image */}
              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="caption"
                  sx={{
                    display: "block",
                    fontSize: "11pt",
                  }}
                >
                  Cover Image
                </Typography>
                {isUpdate && objData.coverImageUrl // Check if it's an update and coverImage exists
                  ? renderImageField(objData.coverImageUrl)
                  : renderUploadFileField(
                      "coverImage",
                      "Allowed *.jpeg, *.jpg, *.png",
                      {
                        "image/png": [".png"],
                        "image/jpg": [".jpg"],
                        "image/jpeg": [".jpeg"],
                      },
                      handleDropCoverImage
                    )}
              </Box>

              <Stack spacing={3} sx={{ mt: 3 }}>
                {renderDropDownField(
                  "type",
                  "Media Type",
                  MEDIA_TYPES_OPTIONS,
                  "None",
                  true,
                  true
                )}
              </Stack>

              {/* Upload File Rectangle with Dropzone and Svg Image */}
              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="caption"
                  sx={{
                    display: "block",
                    fontSize: "11pt",
                  }}
                >
                  Media
                </Typography>
                {isUpdate
                  ? objData.mediaUrl &&
                    objData.type.value !== "TEXT" &&
                    objData.type.value !== "None"
                    ? objData.type.value === "VIDEO"
                      ? renderVideoField(objData.mediaUrl)
                      : objData.type.value === "IMAGE"
                      ? renderImageField(objData.mediaUrl, false)
                      : null
                    : renderEmptyImageField(noDataIMG, false)
                  : videoSrc
                  ? renderVideoBeforeUploadField()
                  : renderUploadFileField(
                      "media",
                      "Allowed *.jpeg, *.jpg, *.png, *.mp4",
                      {
                        "image/png": [".png"],
                        "image/jpg": [".jpg"],
                        "image/jpeg": [".jpeg"],
                        "video/mp4": [".mp4"],
                      },
                      handleDropMedia
                    )}
              </Box>

              {/* Form Actions Bar */}
              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  loading={formLoading || isSubmitting} // Use the loading state along with isSubmitting
                  disabled={formLoading || isSubmitting} // Disable the button based on loading and isSubmitting
                  style={{ borderRadius: 20, textTransform: "capitalize" }}
                  className="bg-gradient-to-br from-red-400 to-orange-400 capitalize"
                >
                  {isUpdate ? "Update Challenge" : "Create Challenge"}
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      )}
    </FormProvider>
  );
};

export default ChallengeForm;
