import { Grid, IconButton, InputAdornment } from "@mui/material";

import {
  RHFRoundedTextField,
  RHFSwitch,
} from "../../../components/minimal/hook-form";
import Iconify from "../../../components/minimal/iconify";
import { IFormProps } from "types/form-props";
import { useBoolean } from "hooks/use-boolean";

interface AdminFormProps extends IFormProps {}

const AdminForm: React.FC<AdminFormProps> = ({ formOperation }) => {
  const password = useBoolean();

  return (
    <>
      <Grid item xs={6}>
        <RHFRoundedTextField
          name="username"
          label="User Name"
          disabled={formOperation === "Update"}
        />
      </Grid>
      <Grid item xs={6}>
        <RHFRoundedTextField name="email" label="Email" />
      </Grid>
      <Grid item xs={6}>
        <RHFRoundedTextField name="phoneNumber" label="Phone Number" />
      </Grid>
      <Grid item xs={6}>
        <RHFRoundedTextField
          name="password"
          label="Password"
          type={password.value ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify
                    icon={
                      password.value
                        ? "solar:eye-broken"
                        : "solar:eye-closed-bold"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <RHFSwitch name="blocked" label="Blocked" />
      </Grid>
    </>
  );
};

export default AdminForm;
