export const PARENT_COLUMNS = [
  { id: "username", label: "Name", width: "22%", isAvatar: true },
  { id: "phoneNumber", label: "Phone Number", width: "22%" },
  { id: "email", label: "Email", width: "24%" },
  {
    id: "blocked",
    label: "Blocked",
    width: "12%",
    isList: true,
    list: [false, true],
    clickable: true,
  },
  { id: "createdAt", label: "Date of Join", width: "22%", isDate: true },
  { id: "actions", width: "10%" },
];
