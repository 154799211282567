import React, { useCallback, useState, useEffect } from "react";

import isEqual from "lodash/isEqual";

import RoundedButton from "../../../components/common/button/rounded-button";

import CustomBreadcrumbs from "../../../components/minimal/custom-breadcrumbs";
import {
  useTable,
  emptyRows,
  getComparator,
} from "../../../components/minimal/table";

import { IStepTableFilters, IStepTableFilterValue } from "../../../types/step";
import { STEP_COLUMNS } from "../../../constant/step";

import DataTable from "../../../components/common/data-table/data-table";

import { applyFilter } from "../../../utils/data-table-filter";

import { useNavigate } from "react-router-dom";

import axios from "axios";
import { HOST_API } from "../../../config-global";
import { handleErrors } from "../../../handlers/axiosErrorsHandler";
import { getStorage } from "../../../hooks/use-local-storage";
import { routes } from "../../../routes/routes";

import { useAuth } from "../../../hooks/useAuth";

import { useSnackbar } from "../../../components/minimal/snackbar";
import { QUESTION_COLUMNS } from "../../../constant/question";
import {
  IQuestionItem,
  IQuestionTableFilters,
  IQuestionTableFilterValue,
} from "../../../types/question";
import { applyNestedFilter } from "../../../utils/data-table-nested-filter";
import QuestionForm from "./components/question-form";

interface StepProps {}

// ----------------------------------------------------------------------

const defaultFilters: IStepTableFilters = {
  title: "",
};

// ----------------------------------------------------------------------

const Step: React.FC<StepProps> = () => {
  const { setAuthenticated } = useAuth();

  const token = getStorage("Token");

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  //Main Table (Award Steps)
  const table = useTable();

  const [tableData, setTableData] = useState([]);

  const [filters, setFilters] = useState(defaultFilters);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  //Nested Table (Question Table)
  const table2 = useTable();

  const nestedDefaultFilters: IQuestionTableFilters = {
    questionText: "",
  };

  const [nestedTableData, setNestedTableData] = useState([]);

  const [nestedFilters, setNestedFilters] = useState(nestedDefaultFilters);

  const nestedDataFiltered = applyNestedFilter({
    inputData: nestedTableData,
    comparator: getComparator(table2.order, table2.orderBy),
    nestedFilters,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios
          .get(`${HOST_API}/api/admin/award-steps`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-locale": "en",
            },
          })
          .then((res) => {
            var response = res.data;
            setTableData(response.data.items);
          });
      } catch (error: any) {
        handleErrors(error, setAuthenticated, enqueueSnackbar);
      }
    };

    fetchData();
  }, [token, setAuthenticated, enqueueSnackbar]);

  const denseHeight = table.dense ? 52 : 72;

  const resetFilters = !isEqual(defaultFilters, filters);

  const notFound =
    (!dataFiltered.length && resetFilters) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name: string, value: IStepTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleAddRow = () => {
    navigate(routes.stepsCreate);
  };

  const handleEditRow = useCallback(
    (id: string) => {
      navigate(routes.stepsEdit(id));
    },
    [navigate]
  );

  const handleDeleteRow = useCallback(
    async (id: string) => {
      await axios
        .delete(`${HOST_API}/api/admin/award-steps/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-locale": "en",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            // If successful, update the table data state
            const deleteRow = tableData.filter((row: any) => row.id !== id);
            setTableData(deleteRow);

            // Update any other relevant state or perform actions
            table.onUpdatePageDeleteRow(dataInPage.length);

            enqueueSnackbar("Delete success!", {
              // variant: 'success',
              // anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
          } else {
            console.error("Delete request failed");
          }
        })
        .catch((error) => {
          handleErrors(error, setAuthenticated, enqueueSnackbar);
        });
    },
    [
      dataInPage.length,
      table,
      tableData,
      token,
      setAuthenticated,
      enqueueSnackbar,
    ]
  );

  // const handleDeleteRows = useCallback(() => {
  //   const deleteRows = tableData.filter((row:any) => !table.selected.includes(row.id));
  //   setTableData(deleteRows);

  //   table.onUpdatePageDeleteRows({
  //     totalRows: tableData.length,
  //     totalRowsInPage: dataInPage.length,
  //     totalRowsFiltered: dataFiltered.length,
  //   });
  // }, [dataFiltered.length, dataInPage.length, table, tableData]);

  const handleChangeState = useCallback(
    (id: string, state: boolean) => {
      axios
        .patch(
          `${HOST_API}/api/admin/award-steps/${id}/published`,
          { published: !state },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "x-locale": "en",
            },
          }
        )
        .then((response) => {
          // Update the published state in tableData (in current row)
          const updatedTableData: any = tableData.map((row: any) => {
            if (row.id === id) {
              return {
                ...row,
                published: !state,
              };
            }
            return row;
          });
          setTableData(updatedTableData); // Update tableData state with the updated data
        })
        .catch((error) => {
          handleErrors(error, setAuthenticated, enqueueSnackbar);
        });
    },
    [token, tableData, setAuthenticated, enqueueSnackbar]
  );

  const nestedDenseHeight = table.dense ? 52 : 72;

  const resetNestedFilters = !isEqual(nestedDefaultFilters, nestedFilters);

  const nestedNotFound =
    (!nestedDataFiltered.length && resetNestedFilters) ||
    !nestedDataFiltered.length;

  const handleNestedFilters = useCallback(
    (name: string, value: IQuestionTableFilterValue) => {
      table2.onResetPage();
      setNestedFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table2]
  );

  const handleResetNestedFilters = useCallback(() => {
    setNestedFilters(nestedDefaultFilters);
  }, []);

  // State to hold selected rows
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const [stepId, setStepId] = useState<number>(-1);
  const [questionId, setQuestionId] = useState<string>("-1");

  // Function to handle row selection
  const handleSelectRow = useCallback(
    async (id: string, selectionMode: string) => {
      // Check if the row is already selected
      const selectedIndex = selectedRows.indexOf(id);
      let newSelected: any = [];

      table.onSelectRow(id);
      if (selectedIndex === -1) {
        // If not selected, add to the selection
        newSelected = newSelected.concat(selectedRows, id);
      } else if (selectedIndex === 0) {
        // If first element is selected, remove it
        newSelected = newSelected.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        // If last element is selected, remove it
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        // If middle element is selected, remove it
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        );
      }

      // Update the selected rows state
      setSelectedRows(newSelected);
      await getQuestions(id);
    },
    [] // if add selectedRows to this array will make checkbox multiple otherwise single selected
  );

  async function getQuestions(id: string) {
    axios
      .get(`${HOST_API}/api/admin/award-steps/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-locale": "en",
        },
      })
      .then((res) => {
        var response = res.data;
        setStepId(response.data.id);
        setNestedTableData(response.data.questions);
      })
      .catch((error) => {
        handleErrors(error, setAuthenticated, enqueueSnackbar);
      });
  }

  // State to manage the visibility of the edit dialog
  const [isQuestionFormOpen, setQuestionFormOpen] = useState(false);
  // State to hold the current question being edited
  const [currentQuestion, setCurrentQuestion] = useState<
    IQuestionItem | undefined
  >(undefined);

  const handleAddQuestion = () => {
    setQuestionId("-1");
    setCurrentQuestion({
      id: -1,
      questionText: "",
      questionImage: undefined,
      questionImageUrl: "",
      questionStep: stepId,
      answers: [],
    });
    setQuestionFormOpen(true); // Open the QuestionForm dialog when clicking on "New Question"
  };

  const handleEditQuestion = useCallback(
    (id: string, row: any) => {
      // Set the questionStep for the current question being edited
      row.questionStep = stepId;
      setQuestionId(id);
      // Set the current question being edited
      setCurrentQuestion(row);
      // Open the edit dialog
      setQuestionFormOpen(true);
    },
    [stepId]
  );

  // Function to handle closing the edit dialog
  const handleCloseQuestionForm = async () => {
    // Reset the current edited question
    setCurrentQuestion(undefined);
    // Close the edit dialog
    setQuestionFormOpen(false);
    await getQuestions(stepId.toString());
  };

  const handleDeleteQuestion = useCallback(
    async (id: string) => {
      await axios
        .delete(`${HOST_API}/api/admin/award-step-questions/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-locale": "en",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            // If successful, update the table data state
            const deleteRow = nestedTableData.filter(
              (row: any) => row.id !== id
            );
            setNestedTableData(deleteRow);

            // Update any other relevant state or perform actions
            table2.onUpdatePageDeleteRow(dataInPage.length);

            enqueueSnackbar("Delete success!", {
              // variant: 'success',
              // anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
          } else {
            console.error("Delete request failed");
          }
        })
        .catch((error) => {
          handleErrors(error, setAuthenticated, enqueueSnackbar);
        });
    },
    [
      dataInPage.length,
      table2,
      nestedTableData,
      token,
      setAuthenticated,
      enqueueSnackbar,
    ]
  );

  return (
    <div>
      {/* Bread Crumb + Create Button */}
      <div className="flex items-center justify-between pb-3 h-12">
        <CustomBreadcrumbs
          links={[
            { name: "Management", href: routes.steps },
            { name: "Step", href: routes.steps },
          ]}
        />
        <RoundedButton
          label="New Step"
          backgroundColor="#637381"
          onClick={handleAddRow}
          icon="mingcute:add-line"
        />
      </div>

      {/* DataTable */}
      <DataTable
        tableName="Step"
        deleteTitle="Step"
        mainCol="title"
        table={table}
        tableCols={STEP_COLUMNS}
        tableData={dataFiltered}
        filters={filters}
        resetFilters={resetFilters}
        denseHeight={denseHeight}
        emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
        notFound={notFound}
        handleFilters={handleFilters}
        handleResetFilters={handleResetFilters}
        handleEditRow={handleEditRow}
        handleDeleteRow={handleDeleteRow}
        handleChangeState={handleChangeState}
        onSelectRow={handleSelectRow}
        selectionMode="single"
        hasSelections={true}
      />

      <div className="flex items-center justify-end py-8 h-12">
        <RoundedButton
          label="New Question"
          backgroundColor="#637381"
          onClick={() => {
            if (stepId !== -1) {
              handleAddQuestion();
            } else {
              enqueueSnackbar(`Please select step before create question!!`, {
                variant: "error",
              });
            }
          }}
          icon="mingcute:add-line"
        />
      </div>

      <DataTable
        tableName="Question"
        deleteTitle="Question"
        mainCol="questionText"
        table={table2}
        tableCols={QUESTION_COLUMNS}
        tableData={nestedDataFiltered}
        filters={nestedFilters}
        resetFilters={resetNestedFilters}
        denseHeight={nestedDenseHeight}
        emptyRows={emptyRows(
          table2.page,
          table2.rowsPerPage,
          nestedTableData.length
        )}
        notFound={nestedNotFound}
        handleFilters={handleNestedFilters}
        handleResetFilters={handleResetNestedFilters}
        handleEditRow={handleEditQuestion}
        handleDeleteRow={handleDeleteQuestion}
        handleChangeState={handleChangeState}
        selectionMode="single"
        hasSearch={false}
        hasEdit={false}
        hasView={true}
      />

      {/* QuestionForm dialog for editing or creating questions */}
      <QuestionForm
        open={isQuestionFormOpen} // Pass the state to manage the edit dialog's visibility
        onClose={handleCloseQuestionForm} // Pass the function to close the edit dialog
        currentQuestion={currentQuestion} // Pass the current edited question
        questionId={questionId}
      />

      <div style={{ marginTop: "2rem" }}></div>
    </div>
  );
};

export default Step;
