import React, { useMemo, useCallback, useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";

import { useNavigate, useParams } from "react-router-dom";

import { getBadgeSchema } from "../badge-schema";

import { fData } from "../../../../utils/format-number";

import {
  ASSETS_API,
  ASSETS_API_PREFIX,
  HOST_API,
} from "../../../../config-global";
import axios from "axios";
import { handleErrors } from "../../../../handlers/axiosErrorsHandler";

import { routes } from "../../../../routes/routes";

import { getStorage } from "../../../../hooks/use-local-storage";
import { useAuth } from "../../../../hooks/useAuth";

import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
  CircularProgress,
  IconButton,
  MenuItem,
  Divider,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useForm } from "react-hook-form";
import FormProvider, {
  RHFRoundedSelect,
  RHFRoundedTextField,
  RHFUpload,
} from "../../../../components/minimal/hook-form";
import { useSnackbar } from "../../../../components/minimal/snackbar";
import Image from "../../../../components/minimal/image";
import Iconify from "../../../../components/minimal/iconify";
import { DOMAIN_OPTIONS, TYPE_OPTIONS } from "../../../../constant/badge";

// ----------------------------------------------------------------------

const styles = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "9999px",
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "#f87171",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#f87171",
    },
  },
  "& label": {
    // color: 'red',
  },
  "& label.Mui-focused": {
    color: "#f87171",
  },
};

// ----------------------------------------------------------------------

interface BadgeFormProps {
  onClose: VoidFunction;
}

// ----------------------------------------------------------------------

const BadgeForm: React.FC<BadgeFormProps> = ({ onClose }) => {
  const { setAuthenticated } = useAuth();

  const [loading, setLoading] = useState<boolean>(true); // State to track loading state

  const [objData, setObjData] = useState<any>({});

  const token = getStorage("Token");

  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams(); // Extract badgeId from the URL
  const isUpdate = id !== undefined; // Check if it's an update

  const BadgeSchema = useMemo(() => getBadgeSchema(isUpdate), [isUpdate]);

  //must default values attributes is equal to badgeSchema
  const defaultValues = useMemo(
    () => ({
      title: "",
      titleAr: "",
      description: "",
      descriptionAr: "",
      type: -1,
      domain: -1,
      totalCount: 0,
      coverImage: null,
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(BadgeSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleDropFile = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue("coverImage", newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleRemoveImage = useCallback(() => {
    setObjData((prevObjData: any) => {
      return { ...prevObjData, coverImageUrl: "" };
    });
  }, [setObjData]);

  const navigate = useNavigate();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const formData = new FormData();
      formData.append("translations[0][locale]", "en");
      formData.append("translations[0][title]", data.title);
      formData.append("translations[1][locale]", "ar");
      formData.append("translations[1][title]", data.titleAr);
      if (!isUpdate || data.coverImage) {
        formData.append("coverImage", data.coverImage); // Assuming coverImage is a File object
      }
      formData.append("type", data.type.toString());
      formData.append("domain", data.domain.toString());
      formData.append("totalCount", data.totalCount.toString());

      // Automatically set the description and descriptionAr based on domain value and totalCount
      let description = "";
      let descriptionAr = "";

      switch (data.domain) {
        case 0:
          description = `Follow ${data.totalCount} Children`;
          descriptionAr = `تابع ${data.totalCount} أطفال`;
          break;
        case 1:
          description = `Create ${data.totalCount} Challenges`;
          descriptionAr = `انشئ ${data.totalCount} من التحديات`;
          break;
        case 2:
          description = `Give ${data.totalCount} Likes`;
          descriptionAr = `امنح ${data.totalCount} لايك`;
          break;
        case 3:
          description = `Add ${data.totalCount} Comments`;
          descriptionAr = `اضف ${data.totalCount} من التعليقات`;
          break;
        case 4:
          description = `Do ${data.totalCount} Challenges`;
          descriptionAr = `انجز ${data.totalCount} تحديات`;
          break;
        case 5:
          description = `Create ${data.totalCount} Story`;
          descriptionAr = `انشئ ${data.totalCount} من الستوريات`;
          break;
        case 6:
          description = `Give ${data.totalCount} Likes`;
          descriptionAr = `امنح ${data.totalCount} لايك`;
          break;
        // Add other cases here if needed
        default:
          description = "Default description"; // Set a default value if needed
          descriptionAr = "الوصف الافتراضي"; // Arabic translation for default description
          break;
      }

      formData.append("translations[0][description]", description);
      formData.append("translations[1][description]", descriptionAr);

      if (isUpdate) {
        // Update operation
        await axios.patch(`${HOST_API}/api/admin/tasks/${id}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-locale": "en",
          },
        });
      } else {
        // Create operation
        await axios.post(`${HOST_API}/api/admin/tasks`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-locale": "en",
          },
        });
      }

      enqueueSnackbar(isUpdate ? "Update success!" : "Create success!", {
        // variant: 'success',
        // anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
      });
      navigate(routes.badges);
    } catch (error: any) {
      handleErrors(error, setAuthenticated, enqueueSnackbar);
    }
  });

  useEffect(() => {
    const fetchBadgeData = async () => {
      try {
        const response = await axios.get(`${HOST_API}/api/admin/tasks/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-locale": "en",
          },
        });
        const badgeData = response?.data?.data; // Assuming the API response contains the badge data
        setObjData(badgeData);
        // Populate form fields with fetched data

        // Find the translation object with locale 'en' in the translations array
        const enTranslation = badgeData.translations.find(
          (translation: any) => translation.locale === "en"
        );
        // If 'en' translation exists, set titleAr to its title, otherwise set it to an empty string
        const titleEnValue = enTranslation ? enTranslation.title : "";
        setValue("title", titleEnValue);
        // Find the translation object with locale 'ar' in the translations array
        const arTranslation = badgeData.translations.find(
          (translation: any) => translation.locale === "ar"
        );
        // If 'ar' translation exists, set titleAr to its title, otherwise set it to an empty string
        const titleArValue = arTranslation ? arTranslation.title : "";
        setValue("titleAr", titleArValue);
        setValue("type", badgeData.type);
        setValue("domain", badgeData.domain);
        setValue("totalCount", badgeData.totalCount);

        setLoading(false);
      } catch (error) {
        handleErrors(error, setAuthenticated, enqueueSnackbar);
        setLoading(false);
      }
    };

    if (isUpdate) {
      // Update State
      fetchBadgeData();
    } else {
      // Create State
      setLoading(false);
    }
  }, [
    id,
    token,
    setValue,
    isUpdate,
    setAuthenticated,
    enqueueSnackbar,
    setObjData,
  ]);

  const renderDropDownField = (
    name: string,
    label: string,
    options: any,
    noSelectedValue: any,
    byValue: boolean,
    readOnly: boolean = false
  ) => (
    <RHFRoundedSelect
      name={name}
      size="small"
      label={label}
      id={name} // Pass the unique id to the RHFRoundedSelect component
      InputLabelProps={{ shrink: true, htmlFor: name + "-label" }} // Match the htmlFor prop
      // InputLabelProps={{ shrink: true }} // Match the htmlFor prop
      // sx={{
      // maxWidth: { md: 160 },
      // }}
      disabled={readOnly} // Add the disabled attribute based on the readOnly parameter
      sx={styles}
    >
      <MenuItem
        value={noSelectedValue}
        // onClick={() => handleClearService(index)}
        sx={{ fontStyle: "italic", color: "text.secondary" }}
      >
        None
      </MenuItem>

      <Divider sx={{ borderStyle: "dashed" }} />

      {options.map((item: any) => (
        <MenuItem
          key={item.id}
          value={item.id}
          // onClick={() => handleSelectService(index, item.value)}
        >
          {byValue ? item.value : item.title}
        </MenuItem>
      ))}
    </RHFRoundedSelect>
  );

  const renderUploadFileField = (
    name: any,
    allowedExt: string,
    accept: any
  ) => (
    <RHFUpload
      name={name}
      maxSize={3145728}
      accept={accept} // Specify accepted file types
      onDrop={handleDropFile}
      onDelete={() => setValue(name, null, { shouldValidate: true })}
      helperText={
        <Typography
          variant="caption"
          sx={{
            mt: 3,
            mx: "auto",
            display: "block",
            textAlign: "center",
            color: "text.disabled",
          }}
        >
          {/* Allowed *.jpeg, *.jpg, *.png, *.gif */}
          {allowedExt}
          <br /> max size of {fData(3145728)}
        </Typography>
      }
    />
  );

  const renderImageField = (imageUrl: string) => (
    <div style={{ position: "relative" }}>
      {/* <Avatar src={objData.coverImageUrl} alt="Cover Image" sx={{ width: 200, height: 200, mt: 1 }} /> */}
      <Card sx={{ p: 1, mt: 0.5 }}>
        <Image
          ratio="1/1"
          alt="gallery"
          src={ASSETS_API + ASSETS_API_PREFIX + imageUrl}
          sx={{
            borderRadius: 1,
            width: "100%",
            height: "300px",
            border: "1px",
            padding: 2,
          }}
          effect="opacity"
        />
      </Card>

      {/* Remove Image Icon */}
      <Box sx={{ position: "absolute", top: -12, right: -12 }}>
        <IconButton
          onClick={handleRemoveImage}
          sx={{
            backgroundColor: "#FF922E",
            "&:hover": { backgroundColor: "#FF7F00" },
          }}
        >
          <Iconify icon={"solar:trash-bin-trash-bold"} color="white" />
        </IconButton>
      </Box>
    </div>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {/* Render loader if loading is true */}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        // Render form when loading is false
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Box
                columnGap={2} // gap between form columns
                display="grid"
                gridTemplateColumns={{
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                }}
                sx={{ mb: 3 }}
              >
                {renderDropDownField(
                  "type",
                  "Type",
                  TYPE_OPTIONS,
                  -1,
                  true,
                  isUpdate
                )}
                {renderDropDownField(
                  "domain",
                  "Domain",
                  DOMAIN_OPTIONS,
                  -1,
                  true,
                  isUpdate
                )}
              </Box>
              <Stack spacing={3} sx={{ mb: 3 }}>
                <RHFRoundedTextField name="totalCount" label="Total Count" />
              </Stack>
              <Box
                columnGap={2} // gap between form columns
                display="grid"
                gridTemplateColumns={{
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                }}
              >
                <RHFRoundedTextField name="title" label="Title (En)" />
                <RHFRoundedTextField name="titleAr" label="Title (Ar)" />
              </Box>

              {/* Upload File Rectangle with Dropzone and Svg Image */}
              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="caption"
                  sx={{
                    display: "block",
                    fontSize: "11pt",
                  }}
                >
                  Cover Image
                </Typography>
                {isUpdate && objData.coverImageUrl // Check if it's an update and coverImage exists
                  ? renderImageField(objData.coverImageUrl)
                  : renderUploadFileField(
                      "coverImage",
                      "Allowed *.jpeg, *.jpg, *.png",
                      {
                        "image/png": [".png"],
                        "image/jpg": [".jpg"],
                        "image/jpeg": [".jpeg"],
                      }
                    )}
              </Box>

              {/* Form Actions Bar */}
              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  loading={isSubmitting}
                  style={{ borderRadius: 20, textTransform: "capitalize" }}
                  className="bg-gradient-to-br from-red-400 to-orange-400 capitalize"
                >
                  {isUpdate ? "Update Badge" : "Create Badge"}
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      )}
    </FormProvider>
  );
};

export default BadgeForm;
