import { CHALLENGE_USER_REPORT_COLUMNS } from "./challenge-user-report-column";
import DataTable from "../../../components/common/data-table/data-table";
import ChallengeUserReportForm from "./challenge-user-report-form";

interface ChallengeUserReportProps {}

const ChallengeUserReport: React.FC<ChallengeUserReportProps> = () => {
  return (
    <div>
      <DataTable
        tableName="Challenge Reports"
        parentTableName="Management"
        deleteTitle="Challenge Report"
        mainCol="title"
        getAllLink="/api/admin/challenge-users/report" //TODO add "api" to base rout
        getItemLink="/api/admin/challenge-users/report" //TODO add "api" to base rout
        updateItemLink="/api/admin/challenge-users/report" //TODO add "api" to base rout
        createItemLink="/api/admin/challenge-users/report" //TODO add "api" to base rout
        hasEdit={true}
        hasDelete={false}
        tableCols={CHALLENGE_USER_REPORT_COLUMNS}
        FormContent={ChallengeUserReportForm}
        tableDataMapper={(rows) => {
          return rows?.map((row: any, index: number) => {
            return {
              ...row,
              isDeleted: !!row?.challengeUser?.deletedAt,
            };
          });
        }}
        formDataMapper={(data: any) => {
          return {
            ...data,
            status: data?.status?.value,
          };
        }}
        formSubmitMapper={(data: any) => {
          return {
            status: data?.status,
          };
        }}
      />
    </div>
  );
};

export default ChallengeUserReport;
