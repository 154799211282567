export const RADIO_Status_OPTIONS = () => [
  {
    label: "Approved",
    value: "APPROVED",
  },
  {
    label: "Rejected",
    value: "REJECTED",
  },
  {
    label: "Pending",
    value: "PENDING",
  },
];
