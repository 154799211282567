import { PARENT_COLUMNS } from "./parent-column";
import DataTable from "../../../components/common/data-table/data-table";
import ParentForm from "./parent-form";
import { ParentSchema } from "./parent-schema";

interface ParentProps {}

const Parent: React.FC<ParentProps> = () => {
  return (
    <div>
      <DataTable
        tableName="Parents"
        parentTableName="Users"
        deleteTitle="Parent"
        mainCol="username"
        getAllLink="/api/admin/users/parents" //TODO add "api" to base rout
        deleteRowLink="/api/admin/users/admins" //TODO add "api" to base rout
        getItemLink="/api/admin/users/parents" //TODO add "api" to base rout
        updateItemLink="/api/admin/users/parents" //TODO add "api" to base rout
        hasDelete={false}
        tableCols={PARENT_COLUMNS}
        FormContent={ParentForm}
        formSchema={ParentSchema}
        formSubmitMapper={(data: any) => {
          return {
            username: data?.username,
            phoneNumber: data?.phoneNumber,
            email: data?.email,
            blocked: data?.blocked,
          };
        }}
      />
    </div>
  );
};

export default Parent;
