import React, { useEffect, useState } from "react";
import { ASSETS_API, ASSETS_API_PREFIX } from "../../../config-global";
import { Grid } from "@mui/material";
import {
  RHFRoundedTextField,
  RHFSwitch,
  RHFUpload,
} from "../../../components/minimal/hook-form";
import { IFormProps } from "types/form-props";
import RHFColorPicker from "components/minimal/hook-form/rhf-color-picker";
import { Upload } from "components/minimal/upload";
import { handleUploadFile } from "utils/helper";

interface CategoryFormProps extends IFormProps {}

const CategoryForm: React.FC<CategoryFormProps> = ({
  currentItem,
  setValue,
}) => {
  return (
    <>
      <Grid item xs={6}>
        <RHFRoundedTextField name="title" label="Title (En)" />
      </Grid>
      <Grid item xs={6}>
        <RHFRoundedTextField name="titleAr" label="Title (Ar)" />
      </Grid>
      <Grid item xs={6}>
        <RHFColorPicker name="backgroundColor" label="Background Color" />
      </Grid>
      <Grid item xs={12}>
        {/* <Upload //Replace to RHFUpload
          file={file}
          onDrop={(acceptedFiles) => handleUploadFile(acceptedFiles, setFile)}
        /> */}
        <RHFUpload //Replace to RHFUpload
          name="coverImage"
          // file={file}
          onDrop={(acceptedFiles) => {
            const newFile = Object.assign(acceptedFiles[0], {
              preview: URL.createObjectURL(acceptedFiles[0]),
            });
            setValue("coverImage", newFile);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <RHFSwitch name="published" label="Published" />
      </Grid>
    </>
  );
};

export default CategoryForm;
