import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Image from "../../../components/minimal/image";
import { ASSETS_API, ASSETS_API_PREFIX } from "../../../config-global";
import { IFormProps } from "types/form-props";
import { RHFRoundedTextField } from "components/minimal/hook-form";
import RHFDatePicker from "components/minimal/hook-form/rhf-date-picker";
import RHFTextValue from "components/minimal/hook-form/rhf-text-value";
import { fDate } from "utils/format-time";
import RHFRadioGroup from "components/minimal/hook-form/rhf-radio-group";
import { RADIO_Status_OPTIONS } from "constant/radio-status-option";
import { useState } from "react";
import PreviewMedia from "components/common/preview-media/preview-media";

interface StoryReportFormProps extends IFormProps {}

const StoryReportForm: React.FC<StoryReportFormProps> = ({
  currentItem: rowData,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Story Report Information" />
          <Divider />
          <CardContent>
            <RHFTextValue label="User" value={rowData?.user?.fullName} />
            <RHFTextValue label="Category" value={rowData?.category} />
            <RHFTextValue
              label="Media Type"
              value={rowData?.userStory?.mediaType?.label}
            />
            <RHFTextValue label="Reason" value={rowData?.reason} />
            <RHFTextValue
              label="Created Date"
              value={fDate(rowData?.createdAt)}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <RHFTextValue
          label="Media Link"
          value={
            <Typography
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              fontWeight={500}
              onClick={toggleForm}
            >
              Preview
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <RHFRadioGroup
          label="Status"
          name="status"
          options={RADIO_Status_OPTIONS()}
          sx={{ display: "flex", flexDirection: "row" }}
        />
      </Grid>
      <PreviewMedia
        urlMedia={rowData?.userStory?.mediaUrl}
        mediaType={rowData?.userStory?.mediaType?.label}
        isOpen={isOpen}
        toggleForm={toggleForm}
      />
    </>
  );
};

export default StoryReportForm;
