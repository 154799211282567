import { STORY_REPORT_COLUMNS } from "./story-report-column";
import DataTable from "../../../components/common/data-table/data-table";
import { StoryReportSchema } from "./story-report-schema";
import StoryReportForm from "./story-report-form";

interface StoryReportProps {}
const StoryReport: React.FC<StoryReportProps> = () => {
  return (
    <div>
      <DataTable
        tableName="Story Reports"
        parentTableName="Overview"
        deleteTitle="Story Report"
        mainCol="title"
        getAllLink="/api/admin/user-stories" //TODO add "api" to base rout
        deleteRowLink="/api/admin/user-stories" //TODO add "api" to base rout
        getItemLink="api/admin/user-stories/report" //TODO add "api" to base rout
        updateItemLink="api/admin/user-stories/report" //TODO add "api" to base rout
        tableCols={STORY_REPORT_COLUMNS}
        formSchema={StoryReportSchema}
        FormContent={StoryReportForm}
        // formSchema={ParentSchema}
        hasSearch={false}
        hasEdit={true}
        hasDelete={false}
        tableDataMapper={(rows) => {
          return rows?.map((row: any, index: number) => {
            return {
              ...row,
              userType: row?.user?.userType,
              isDeleted: !!row?.userStory?.deletedAt,
            };
          });
        }}
        formDataMapper={(data: any) => {
          return {
            ...data,
            mediaType: data?.mediaType?.label,
            status: data?.status?.value,
          };
        }}
        formSubmitMapper={(data: any) => {
          return {
            status: data?.status,
          };
        }}
      />
    </div>
  );
};

export default StoryReport;
